/**
 * The entry point for ScriptsBundler.
 */

// CSS
require("./scss/styles.scss");
require("./scss/styles.backoffice.scss");
require("./scss/contrast.scss");

// JS
require("core-js/es/promise");

// NOTE:
// We expose our own version of jQuery library as $jquery global object.
// This $jquery global object will be extended with extra functionality.
// $jquery MUST BE used instead of $ and jQuery on views outside the frontend project.
// $ and jQuery CAN BE safely used inside local JS modules - as these aliases will point to a jQuery instance inside Webpack closure, not to the global jQuery object.
global.$jquery = require("jquery");

// NOTE:
// If somebody has already provided an instance of $ and jQuery before our initialization, we shouldn't override it.
// Otherwise, we expose our instance of the global object.
if (!global.$ || !global.jQuery) {
    global.$ = global.jQuery = global.$jquery;
}

require("jquery-validation");
require("jquery-validation/dist/additional-methods");
require("jquery-validation-unobtrusive");

require("bootstrap");

require("slick-carousel");

require("./js/extensions/jquery.extensions");
require("./js/extensions/jquery-validation.extensions");

require("./js/pcg.frontend").init();
