var geolocationService = require("./services/geolocation.service");
var pageHeaderComponent = require("./components/page-header.component");

var AccessibilityWidgetComponent = require("./components/accessibility-widget.component");
var UserRegionProgramsComponent= require("./components/region-programs/user-region-programs.component");
var CountryRegionsListComponent= require("./components/region-programs/country-regions-list.component");
var RegionProgramContactsComponent= require("./components/program-contacts/region-programs-contacts.component");
var HomeSlick = require("./components/homeslick.component");
var Collapsible = require("./components/collapsible.component");
var VideoBoxComponent = require("./components/video-box/video-box.component");
var VideoPopUpComponent = require("./components/video-box/video-pop-up.component");
var GoTopButton = require("./components/go-top-button.component");
var NotificationService = require("./components/notification-pane/notification-panes.service");
var RollingNumbersService = require("./components/rolling-number/rolling-numbers.service");
var CommentFormsService = require("./components/comment-form/comment-forms.service");
const ScrollTo = require("./components/scroll-to/scroll-to.component");
var CookiesService = require("./services/cookies.service");

function PcgFrontend() {
    this.init = function() {
        $.pcg = $.pcg || {};
        $.pcg.frontend = $.pcg.frontend || {};

        $.extend($.pcg.frontend, {
            pageHeader: pageHeaderComponent,
            accessibilityWidget: new AccessibilityWidgetComponent(),
            geolocationService: geolocationService,
            userRegionPrograms: new UserRegionProgramsComponent(),
            regionProgramsContacts: new RegionProgramContactsComponent(),
            countryRegionsList: new CountryRegionsListComponent(),
            homeSlick: HomeSlick,
            collapsible: new Collapsible(),
            videoBox: VideoBoxComponent,
            videoPopUp: VideoPopUpComponent,
            goTopButton: new GoTopButton(),
            notificationService: new NotificationService(),
            rollingNumberService: new RollingNumbersService(),
            commentFormsService: new CommentFormsService(),
            scrollTo: new ScrollTo(),
            cookiesService: CookiesService
        });

        console.info(`>> cms-web-frontend/js module initialized`);

        if (Object.getOwnPropertyNames($.pcg.frontend).length) {
            console.debug(`>> available components and services:`);
            Object.getOwnPropertyNames($.pcg.frontend).forEach(function (name) {
                console.debug(`>> • ${name}`);
            });
        }
    };
};

module.exports = new PcgFrontend();
