const ScrollTo = function () {
    let $currentContext = $();
    let $element = $();

    const moduleOptions = {
        scrollSpeed: 1000
    };

    const classMap = {
        button: ".js-pcg-scroll-to"
    };

    function initContext() {
        $element = $(classMap.button, $currentContext);
    }

    function bindEvents() {
        $element.on("click", function (e) {
            e.preventDefault();
            let href = $(this).attr("href");

            $("html, body").animate({
                scrollTop: $(href).offset().top
            }, {
                duration: 600,
                easing: "linear"
            });

            return false;
        });
    }

    this.init = function (context, options) {
        $currentContext = context || $currentContext;
        $.extend(moduleOptions, options);

        initContext();
        bindEvents();
    };

    return this;
};

module.exports = ScrollTo;
